<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title=this.pageTitle
      :subtitle=this.pageSubTitle
      :hide-buttons="true"
    >
      <div class="vx-row">
        <div class="total vx-col w-full">
          <label class="w-full select-large">Destinatarios</label>
          <vs-chips color="rgb(145, 32, 159)" placeholder="Destinatarios" v-model="recipients"
                    remove-icon="delete_forever" class="mt-1 recipients-list">
            <vs-chip
              :key="recipient"
              @click="remove(recipient)"
              v-for="recipient in recipients" closable close-icon="delete">
              {{ recipient }}
            </vs-chip>
          </vs-chips>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col md:w-1/2 w-full">
          <label for="start_date" class="w-full select-large">Fecha inicial</label>
          <flat-pickr
            :config="configDateTimePicker"
            id="start_date" name="start_date" placeholder="Fecha inicial"
            v-model="startDate"
            v-validate="'required'"
            class="w-full flatpickr flatpickr-input"
          />
          <span class="text-danger">{{ errors.first("start_date") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="end_date" class="w-full select-large">Fecha final</label>
          <flat-pickr
            :config="configDateTimePicker"
            id="end_date" name="end_date" placeholder="Fecha final"
            v-model="endDate"
            v-validate="'required'"
            class="w-full flatpickr flatpickr-input"
          />
          <span class="text-danger">{{ errors.first("end_date") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToFormsList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="requestReportGeneration" :disabled="!fieldsValidated">Generar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import EntriesClient from "../../utils/entriesClient";
  import CRMClient from "../../utils/CRMClient";
  import Notifier from "./../../utils/notification";

  import {Spanish} from 'flatpickr/dist/l10n/es.js';
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";

  let notifier = new Notifier();
  const ENTRY_CLIENT = new EntriesClient("promotions");
  const CRM_CLIENT = new CRMClient("promotions");


  export default {
    data() {
      return {
        pageTitle: "Generar Reporte de Solicitudes",
        pageSubTitle: "",
        configDateTimePicker: {
          enableTime: true,
          dateFormat: "Y-m-d H:i:S",
          altInput: true,
          altFormat: "Y-m-d H:i",
          locale: Spanish,
        },
        recipients: [],
        startDate: null,
        endDate: null,
      };
    },

    computed: {
      fieldsValidated() {
        return !this.errors.any() && this.recipients.length > 0 && this.startDate !== null && this.endDate !== null;
      }
    },

    methods: {
      remove (recipient) {
        this.recipients.splice(this.recipients.indexOf(recipient), 1)
      },

      async getEntry() {
        await ENTRY_CLIENT.retrieve({
          queryStrings: {representation_type: "simple"},
          pathParameters: {entryId: this.$route.params.entryId}
        })
          .then(response => {
            this.pageSubTitle = response.data.name;
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async requestReportGeneration() {
        this.$vs.loading();
        await CRM_CLIENT.requestReportGeneration(
          {
            pathParameters: {entryId: this.$route.params.entryId},
            data: {
              recipients: this.recipients,
              start_date: this.startDate,
              end_date: this.endDate,
            }
          }
        )
          .then(response => {
            this.$vs.loading.close();
            notifier.notification("created");
            this.goToFormsList();
          })
          .catch(error => {
            this.$vs.loading.close();
            notifier.alertMessage("error");
          });
      },

      goToFormsList() {
        this.$router.push({name: "promotions-inbox", params: {entryId: this.$route.params.entryId}});
      },
    },
    components: {
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>

<style scoped>
  .recipients-list {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>